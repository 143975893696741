<template>
  <div class="user">
    <top />
    <div class="container margin">
      <side-nav>
        <div class="user__content">
          <div class="user__title text__center">学分明细表</div>
          <a-table
            :loading="tableLoading"
            :columns="columns"
            :data-source="data"
            :rowKey="(record) => record.id"
            :pagination="pagination"
            @change="paginationChange"
          >
            <template slot="action" slot-scope="text, record">
              <a @click="toDetail(record)">查看</a>
            </template>
          </a-table>
        </div>
      </side-nav>
    </div>
  </div>
</template>

<script>
import { userScoreLog } from "@/api/user";
const columns = [
  {
    title: "名称",
    dataIndex: "sourceEventName",
    key: "sourceEventName"
  },
  {
    title: "学分来源",
    dataIndex: "sourceTypeName",
    key: "sourceTypeName"
  },
  {
    title: "相关资源分类",
    dataIndex: "remark",
    key: "remark"
  },
  {
    title: "获得学分",
    dataIndex: "score",
    key: "score"
  },
  {
    title: "获得时间",
    dataIndex: "createTime",
    key: "createTime"
  }
];

// const data = [
//   {
//     key: "1",
//     name: "JAVA的小秘密",
//     source: "直播",
//     classify: "学习力",
//     credit: "5",
//     date: "2021年6月23日 15:00"
//   },
//   {
//     key: "2",
//     name: "EXCEL灵活运用",
//     source: "直播",
//     classify: "十分钟学习",
//     credit: "5",
//     date: "2021年6月23日 15:00"
//   }
// ];

// import { userScore } from "@/api/user.js";
import sideNav from "@/components/side-nav";
import top from "../components/top.vue";
export default {
  components: { sideNav, top },
  data() {
    return {
      data: [],
      columns,
      tableLoading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.tableLoading = true;
      userScoreLog({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(res => {
        this.tableLoading = false;
        this.data = res.result.records;
        this.pagination.total = res.result.total;
      });
    },
    paginationChange(page) {
      console.log(page);
      this.pagination.current = page.current;
      this.pagination.pageSize = page.pageSize;
      this.getData();
    },
    toDetail(row) {
      console.log(row);
      this.$router.push({
        name: "cerDetails"
      });
    }
  }
};
</script>

<style lang = "scss" scoped>
.user {
  &__title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 16px;
  }
}

::v-deep .ant-table-column-title {
  color: #C4A468;
  font-weight: bold;
}

::v-deep .ant-table-thead>tr>th {
  background: #F7F3EC;
  padding-left: 37px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #C4A468;
}

::v-deep .ant-table-row>td {
  padding-left: 37px;
}
</style>
